import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/SD_Card_Access/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "SD Card Access - Bitkinex",
  "path": "/Motion_Detection/SD_Card_Access/Bitkinex/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Access the SD Card with Third-party Software",
  "image": "./MD_SearchThumb_SDCardAccess_Bitkinex.png",
  "social": "/images/Search/MD_SearchThumb_SDCardAccess_Bitkinex.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-SD-Card_white.webp",
  "chapter": "Motion Detection"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='SD Card Access - Bitkinex' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Access the SD Card with Third-party Software' image='/images/Search/MD_SearchThumb_SDCardAccess_Bitkinex.png' twitter='/images/Search/MD_SearchThumb_SDCardAccess_Bitkinex.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/SD_Karten_Zugriff/Bitkinex/' locationFR='/fr/Motion_Detection/SD_Card_Access/Bitkinex/' crumbLabel="Bitkinex" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "bitkinex---ftp-access-to-the-internal-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bitkinex---ftp-access-to-the-internal-sd-card",
        "aria-label": "bitkinex   ftp access to the internal sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bitkinex - FTP Access to the internal SD Card`}</h2>
    <p>{`Bitkinex for Windows is a free FTP client that you can use to access your cameras SD card. We recommend you to download the latest version from `}<a href="http://www.bitkinex.com/download" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "528px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/4af8e/Bitkinex-SD_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACqklEQVQoz12R227bRhCG+aR5gDxGLhLYQGo7dW96F6AtkNv2JoUVIVJkOkojxi5aUzIt8SCel9wzd7lLiltYiQvHH76bweDHzGCs4x9OT09//MrpN346Pj5+/uLFwcHB4eHh0dHRq/85OXl5cvLq2bPRkye/P31qXXycz2az0Wh0NhqNx+PJdGLb9nz+6fOexWLhOM6XB9yVjvPPYvH+7MxaR3kY+utbD8JaCtHvumHXq1ZKIRBCQojmHn4PY0y0rbtcWl/c9NYLK0SLmjCpedsxqZnUiPC6qjqtlVJ6b6f1bk/f98aYzWZj9V23CXI7VGMXXYTdXm0HnRO3pGGgBm2nVacgxZizbh8bhsEY4/u+ZYwpQA0QyyGhohPaNGondobkdXy5iv72cjfIrv3gyksv3V7Kx+EsK5I0IxipVt61dl2764hz+dev47PXf779+Q/7zXT0y/j6t7eG0eH78LDeBtusytI0STMplVKKUEoxoXkO41jUsCeE5SXOcqXUd5OHYbdc3dQ1ZJRQxgjBWmtjTKtUDkCFEOGcMFZjDDHuH988mG1cAQAwQlVVQQgZo03TUEqLonj4KSll294tJqVQqvU8z0qSLEliznkJSkwIxhhCyDlHCKVpxhgvyrKqYA1RWYKqhmmaVRVkrFksHEvItK5DQgiogVZ6uIdSgvDWGMqbRLaF0qARmdKA0lhpYAx13c+WMXHbRgiRJM1LAIVom0YI0UJIELo2JhiG0JiHRsMQGBMFwSfLmKTrAoQ2sF5j5FMaMhZxvmUsUmptTGRM8Mhh8I2JfH9uMbYk+LYobiBcl+WqaWKEfAA8AG7K0uM81bpSCjxS68p1HevD9P35uT2bfpxOz9+9m9jn88lkdnX172p1u1x6m00YBNuH+n7k+1EYxrZ98R//tVmG43IH2gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/e4706/Bitkinex-SD_01.avif 230w", "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/d1af7/Bitkinex-SD_01.avif 460w", "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/26e35/Bitkinex-SD_01.avif 528w"],
              "sizes": "(max-width: 528px) 100vw, 528px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/a0b58/Bitkinex-SD_01.webp 230w", "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/bc10c/Bitkinex-SD_01.webp 460w", "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/83811/Bitkinex-SD_01.webp 528w"],
              "sizes": "(max-width: 528px) 100vw, 528px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/81c8e/Bitkinex-SD_01.png 230w", "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/08a84/Bitkinex-SD_01.png 460w", "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/4af8e/Bitkinex-SD_01.png 528w"],
              "sizes": "(max-width: 528px) 100vw, 528px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ac0e0ee72655b20b1272ab2c4472fc3e/4af8e/Bitkinex-SD_01.png",
              "alt": "Bitkinex - FTP Access to the internal SD Card",
              "title": "Bitkinex - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`Set a new data source - choose `}<strong parentName="p">{`HTTP/WebDAV`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "531px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d73905076713987943b0d842d4843e0a/d4713/Bitkinex-SD_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB+UlEQVQoz42SXW/aMBSG/bOr/owJKtYV7nexy2rVPm6KBAzKd6GkBSdxYvKFbRKTQAjxmYBdMDTRPXp0dGydV0eyjIofPt3e3h2pVCrlcrly4OamWCgUisViqVSqnHBXLn8slX5cXd1fX6NqtarjmaZNhs+D7uC50+2Mx+OJNtENg/zBOoMQQj3vqddD7XbHdnkQBJyzqeVRx+OMCcGllEmSrP9FkiRZlmmTCRq9aK/YnxOapikAgIJ3UWo/1Gq1kK7jPAffGYbc8r2FEDxexeEyTLfby+FarYZ0jAGAuZ2IG4uACy5WcrVaxceJC+F6vb7fvMlyanf5gjiOJ8Qyy3ebzebd8H4zns02KqcvI/L5y9vXb/The9ofHkcuh5vNJtJ1Xand/E2j9w9BrbFqNDNd/5/weDxCmqZxFno+M0zTNAjjgi9DxjhjnHNxdN8zcbxkjC8WLIpkvz9AvV7bD+aWheeUuK7lerbvU+oQzv00jdM0jqRwfRonkRc4UgrHtRnzlNqORgOEcQvAzXMLwP5b60T7tCplATiEtBHGDYB5npsAJgA51DONM5XSAWzTbKHZ7BcoXcrXKJpG0ZuU0/XakHIq5f4Yx3i3o2dmmQ3gznAbPT7+nE6Htk1M0ySmaduW6zqGcXg+w6CUhuFyuRSnHn5+NBj0fwMjGTK6rgTsrgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d73905076713987943b0d842d4843e0a/e4706/Bitkinex-SD_02.avif 230w", "/en/static/d73905076713987943b0d842d4843e0a/d1af7/Bitkinex-SD_02.avif 460w", "/en/static/d73905076713987943b0d842d4843e0a/2cd78/Bitkinex-SD_02.avif 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d73905076713987943b0d842d4843e0a/a0b58/Bitkinex-SD_02.webp 230w", "/en/static/d73905076713987943b0d842d4843e0a/bc10c/Bitkinex-SD_02.webp 460w", "/en/static/d73905076713987943b0d842d4843e0a/a33a1/Bitkinex-SD_02.webp 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d73905076713987943b0d842d4843e0a/81c8e/Bitkinex-SD_02.png 230w", "/en/static/d73905076713987943b0d842d4843e0a/08a84/Bitkinex-SD_02.png 460w", "/en/static/d73905076713987943b0d842d4843e0a/d4713/Bitkinex-SD_02.png 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d73905076713987943b0d842d4843e0a/d4713/Bitkinex-SD_02.png",
              "alt": "Bitkinex - FTP Access to the internal SD Card",
              "title": "Bitkinex - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`Right-click your source and choose `}<strong parentName="p">{`Properties`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "535px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9a3ef0f23320f9ed6888281d6c686033/b5245/Bitkinex-SD_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACPElEQVQoz42PfU/aUBjF+/2/hiaKiLrE8DKHWS2jVgTBydJSpZS2ty+0t0B7e/t6nwVdFqeL8ZeT8985J4c7PTkTBIHn+W63y19dXV52eZ4XBEHoPVuv1++L0ivE62tRFO8F4eLsjLvsDTTDNi3DsszlcomQZds2QigMwyiK4jiO3rHdbpM8v59OuZvbu4mMlrpJCGXPVM+UZVkURfk/iqJgVXUjSZyiKGmSudYjiVaE0ISQlFISk6Io4UN4nucURQYA373f4IXn4TAMKaUJScryE+GZojAA1xxFwSP23DAI4t3RmDH2mWUlyQrT0ccq6o6RZgUA8Pf5BxUv4Rmw0kLL5sg5vAmup7ppWZvNllIaYEwIeR97aRwMBrvlLE39VRi41sZH4Tr0VysSx+nuOUEIxXFMCHnjlNLRaMTd3g5s2zZN42k+1/Tldh1G8ZbBrjsvcuQ4GGPbsdfrte24GGPTsjzPS9N0OBxyv6YigJMQrWB26inqaffhm7j4MTYHU7UrrdUJAyfLDMacLDerys4yoygsACzLEifLPQCzqjQAI/PuxnsHd1++DxsXk/Mr5bwXPggAOmMLAA3gjzM2BzBUtc/Jch8AFeWCVXqaaaH7FPkudp04DCpK/UCtqkVV6a9VlgsAazYTOUnitfnoUf2pKNP5fKao8ydNsx3Xx9j1VqapU7pNkn9EyCbPyWQy4vb292u1eq12VK8fHzdODg4PGo2TdrvdbDZbrVan02m336rVanc6X4+O6r8BPgIrvfBmJj4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a3ef0f23320f9ed6888281d6c686033/e4706/Bitkinex-SD_03.avif 230w", "/en/static/9a3ef0f23320f9ed6888281d6c686033/d1af7/Bitkinex-SD_03.avif 460w", "/en/static/9a3ef0f23320f9ed6888281d6c686033/dc608/Bitkinex-SD_03.avif 535w"],
              "sizes": "(max-width: 535px) 100vw, 535px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9a3ef0f23320f9ed6888281d6c686033/a0b58/Bitkinex-SD_03.webp 230w", "/en/static/9a3ef0f23320f9ed6888281d6c686033/bc10c/Bitkinex-SD_03.webp 460w", "/en/static/9a3ef0f23320f9ed6888281d6c686033/e0c9f/Bitkinex-SD_03.webp 535w"],
              "sizes": "(max-width: 535px) 100vw, 535px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9a3ef0f23320f9ed6888281d6c686033/81c8e/Bitkinex-SD_03.png 230w", "/en/static/9a3ef0f23320f9ed6888281d6c686033/08a84/Bitkinex-SD_03.png 460w", "/en/static/9a3ef0f23320f9ed6888281d6c686033/b5245/Bitkinex-SD_03.png 535w"],
              "sizes": "(max-width: 535px) 100vw, 535px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9a3ef0f23320f9ed6888281d6c686033/b5245/Bitkinex-SD_03.png",
              "alt": "Bitkinex - FTP Access to the internal SD Card",
              "title": "Bitkinex - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4`}</h3>
    <p>{`Set the default source directory to `}<strong parentName="p">{`/sd`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "595px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9489ead99907ec2187d5b5625d4d85c4/3dd3e/Bitkinex-SD_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACFElEQVQoz2WSXW/TMBSG85v3KyYBai/WpN0KgYGGtFsQ40NCSAxNa+lFS7fR1YnrLFG6OJ/Np+3Yhjbb2MajVzo35z3nvLKVdnu33+/v7u31ej1VVTVN03X9eX+vq6ntVqvdaqlqp9vVulqDqqmdnW7v1fb2+60tZTAYvj04GA1/nk2nQRAghPbfHHz++v3Lt+Pj09HZzDi/NGcAzk1kQGth2ch2rmwHYzwej5Ur2+51eyc/TihhUsq6rn/P5sBEyHKwHzEuKeOsFjUXXEgh1wixrgghZbFY6C/00XAU4bCuayFEnuWO40RhxCh7AGV0I0KolNI0TeV0MOjs7NjwylviZmQURt41DvygqgjnvF7D74uxWkoJoamUZXl4ePjp6GO2yhzLSVdpEic+DiilYsOt/x+MsZvNSZK81PWjdx+u7aXruFVZ+UE8N61rHAbRyvOjNK8ol6QWd6oY51IaJlQgXLze359OpnjpFXkppchKQtk6W1WWZVE2e+7TpAMGVMbj8bMnTy8vZq7j+p6/NtBaPOp+KL4xzw2oIIQMw5CbeFJKznmaFX6YhHEaxKskLQgTj1RRLpqzozC8u6QxE0KKDXmek4rI/2iaIYQKxphzTunNm66dZUkpJRsqQrK8ilZZnBVZlsdRnGV5mqZFUQAAFACA53mu6y5vcR9iWu7kwjgHlgkXvyYTE0IAwGx2+fd7/gHmdb6TT8kziwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9489ead99907ec2187d5b5625d4d85c4/e4706/Bitkinex-SD_04.avif 230w", "/en/static/9489ead99907ec2187d5b5625d4d85c4/d1af7/Bitkinex-SD_04.avif 460w", "/en/static/9489ead99907ec2187d5b5625d4d85c4/20cf3/Bitkinex-SD_04.avif 595w"],
              "sizes": "(max-width: 595px) 100vw, 595px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9489ead99907ec2187d5b5625d4d85c4/a0b58/Bitkinex-SD_04.webp 230w", "/en/static/9489ead99907ec2187d5b5625d4d85c4/bc10c/Bitkinex-SD_04.webp 460w", "/en/static/9489ead99907ec2187d5b5625d4d85c4/536c8/Bitkinex-SD_04.webp 595w"],
              "sizes": "(max-width: 595px) 100vw, 595px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9489ead99907ec2187d5b5625d4d85c4/81c8e/Bitkinex-SD_04.png 230w", "/en/static/9489ead99907ec2187d5b5625d4d85c4/08a84/Bitkinex-SD_04.png 460w", "/en/static/9489ead99907ec2187d5b5625d4d85c4/3dd3e/Bitkinex-SD_04.png 595w"],
              "sizes": "(max-width: 595px) 100vw, 595px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9489ead99907ec2187d5b5625d4d85c4/3dd3e/Bitkinex-SD_04.png",
              "alt": "Bitkinex - FTP Access to the internal SD Card",
              "title": "Bitkinex - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-5",
        "aria-label": "step 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 5`}</h3>
    <p>{`The server address is your cameras local IP or DDNS address. Type in your camera login below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "595px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/3dd3e/Bitkinex-SD_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACIUlEQVQoz31RXWsaURD1P+cX5CEUQkJiMSpN9KGhJbYUIygSUshTX6zQFoxJMa7uet3VXVfd2d37/VHWj5KX9nAYuNw5zJwzucLFu2r1qlwuF4vFQqFweXlZrVyVy6Wzs9PTk5Pz/FmpeFHccIdC4W2pVD84+HB4mGu32/d399ZgiCYoTdOHh4fal8a3zo9f/UHvedgfjH4P7ZeRY40nYwdNkDv1Zp43W4TL+m091+128+f5YB5opY0xi8ViMLCs8WS5joU2QhmpjdIm+9tD6+zVarVy3zudSqUydaZxFBtjpBCBH0wnU4iAMYYxwSkmm5okKaWMc8EYV0o3m83c++vrr3f3vuenCTbGMMbm7gwhdzyyJw6ybWe1XkdRBBEswxXjwhijlDLGZOLVanV8fBz4gYc8fxZQSqVUWmsppZJq27ddNQaACBBCYRhijLO1EUJvjo6cF9se2jHElNIkSYJggQnT2RAt92RccCEZ45TxnedOp3Pz8Sb0w8XMzzxLCWtghAVLmIdAhCFc7yh2NeVZtq1WM1f7VPt8U/Nncxe5EIEQIgaIAfxlPEIeYE6EwVxvqLZMqJR6I7YsaxmGf8/AORdCMM5UZtzIV2tLpbYUUu4Cy9LT6rV4e8b/YNuQiZVSlFKxB8aYEEL3IIRCQtaQJpjGcRwBYIwBgFKaiX3fd13X+weQ6z0PnZ/9wYs9fX566vUebcd57D2OR+NG4/YPPJy5E+rqARIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e744dcaf7f34a28a6e724c56c1f6954/e4706/Bitkinex-SD_05.avif 230w", "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/d1af7/Bitkinex-SD_05.avif 460w", "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/20cf3/Bitkinex-SD_05.avif 595w"],
              "sizes": "(max-width: 595px) 100vw, 595px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3e744dcaf7f34a28a6e724c56c1f6954/a0b58/Bitkinex-SD_05.webp 230w", "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/bc10c/Bitkinex-SD_05.webp 460w", "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/536c8/Bitkinex-SD_05.webp 595w"],
              "sizes": "(max-width: 595px) 100vw, 595px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3e744dcaf7f34a28a6e724c56c1f6954/81c8e/Bitkinex-SD_05.png 230w", "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/08a84/Bitkinex-SD_05.png 460w", "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/3dd3e/Bitkinex-SD_05.png 595w"],
              "sizes": "(max-width: 595px) 100vw, 595px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3e744dcaf7f34a28a6e724c56c1f6954/3dd3e/Bitkinex-SD_05.png",
              "alt": "Bitkinex - FTP Access to the internal SD Card",
              "title": "Bitkinex - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-6",
        "aria-label": "step 6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 6`}</h3>
    <p>{`Choose standard HTTP for the SD directory and click `}<strong parentName="p">{`Ok`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "596px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/897502d84c299064096447db666156f6/699b7/Bitkinex-SD_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACQElEQVQoz2XRyVPbMBQHYP/vzHApPZZi6BAc6Kl0oSkpgWx2CMulQ9tpCyENsR3vjiTLkRdJtjpJoAPtNzpIh5/03pO0tbW993pPqVYVRalUKtVqdW93V1EUWd5YX38hy/JOpbJTeWR7+5Wi1FZX362sSLVaTetqE90EAJRl0Wy29g/qndPLL99vfhvOyHDGlmfavuUGrj8NQhACBCBKKW2pXanZbG3KmxjhsiyFEBjjieW4bhDhWZazNMuTLE8zmuU0z2lOKaUso1QI0ev1pEaj8fbNvjkyIhQJISilnudPzImhm47tIIR4wYunGOdCCE3VpI2XG2da3zbthCRCiCzLKGVlWfKFoijK/xRFMQ9rmuQ4zvO1NRIT27BCL0yTlLH5xY+U/6yyfAhfXV09f7bm6LYx0klMkoTwNOWc3aPsKc4YX5Y2D7fb7YP3H2AIp34ohEhublCzTQdDLgRbFs7nXXPOGWXzieV5nmbzl3s9SZblz7VD13Zt045Jkk4s76QDrweQpNFwZH08vDtu3dUbxnHL6/am/XNwdjE9v4w7qtZqSYPBII7jv/3ljEU49oNgliTEdUf1I7PX9y8uf36q3zZO8NdvE1UbHB2H/fPTrioJIZbTW2KUYhz5no8xRhEOIcQzktLccT3b9ShjQRj++HWdMnba70tlWS6+5x4hBC0ACCGAURQBAMJwCiECACCEIISWZRFCVFWVZrMZhBA9WOzvj1GEIASu647Huh/4uq4Pb4e+H4zHY8dxu93OHzXas+YTBJrDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/897502d84c299064096447db666156f6/e4706/Bitkinex-SD_06.avif 230w", "/en/static/897502d84c299064096447db666156f6/d1af7/Bitkinex-SD_06.avif 460w", "/en/static/897502d84c299064096447db666156f6/4deda/Bitkinex-SD_06.avif 596w"],
              "sizes": "(max-width: 596px) 100vw, 596px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/897502d84c299064096447db666156f6/a0b58/Bitkinex-SD_06.webp 230w", "/en/static/897502d84c299064096447db666156f6/bc10c/Bitkinex-SD_06.webp 460w", "/en/static/897502d84c299064096447db666156f6/3cb93/Bitkinex-SD_06.webp 596w"],
              "sizes": "(max-width: 596px) 100vw, 596px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/897502d84c299064096447db666156f6/81c8e/Bitkinex-SD_06.png 230w", "/en/static/897502d84c299064096447db666156f6/08a84/Bitkinex-SD_06.png 460w", "/en/static/897502d84c299064096447db666156f6/699b7/Bitkinex-SD_06.png 596w"],
              "sizes": "(max-width: 596px) 100vw, 596px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/897502d84c299064096447db666156f6/699b7/Bitkinex-SD_06.png",
              "alt": "Bitkinex - FTP Access to the internal SD Card",
              "title": "Bitkinex - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-7",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-7",
        "aria-label": "step 7 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 7`}</h3>
    <p>{`Connect to the camera by choosing to `}<strong parentName="p">{`Browse`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "531px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e01dca406748ec236bcd3be486d54eda/d4713/Bitkinex-SD_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.08695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACOElEQVQoz42O207bQBBA/dFI/AYllaBOeGyf2odKRUhNhSglJKQQck8IaRzbu2uvL7u+beL4slORSlBVtPTovIw0ZzTK61eq+qaqqmpVVY9qD1SrtdpRbb+yv7e3V6nsHxwc1H7jUFUPK5UvOzvvd3eVr98u7+4mw9Go0+3ddPudm+v+YDgcjhbz+ULTls+haZppWeeNhnJxPbrXLUIwdqLp0qYOJY5PiLNmTKxW6+cQQpRFcXt7q8zuf9zdE0qczWYD/4eUEgDOzs4UglGZS4f0Qm67rh+GoUhEGIRZlv87rtfrCsZIAnhWO2DEcVjAuEiSVSJkUUIpX47TrCDGdeIZDqWRiLOi2OTZi28/xAghka4Nm5x00If69KY9T6m3sj1BvBWiMt38LT49PVUIIWW2NhHqGfzqY+Pz0fHlu5Ph8VX3U7P39jhF+HH7j7jdbiuLxYL5AaUe0jVXXwhDj7TlxrJySgNNswjhPPR9xhj3ffZLz/PjOGk2W8po1LcpNtFSN5c2tTjjLI5LgFxKx/ejiEcxo5QIETmuHcXcshBjrpR5u91SMO4B2GWJAEjADKwRjFESxUkUuw5dr5cAWEoE8GRZmgB0PG4oGHe2swZg+Hyq6zNCLMM0t6/6q9UcQJdyCfDkdhmPx+eKaXYA9DRdrFMtirSA29RxGWNpmnLOOJ9JifMcFcWTWWYCOIPBhdJqnY+G3weD7mDQn0ymk+kMIWTbtuu65gNGGIZBwB/dXmRCJK1W8ycSDCrRgXU6AQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e01dca406748ec236bcd3be486d54eda/e4706/Bitkinex-SD_07.avif 230w", "/en/static/e01dca406748ec236bcd3be486d54eda/d1af7/Bitkinex-SD_07.avif 460w", "/en/static/e01dca406748ec236bcd3be486d54eda/2cd78/Bitkinex-SD_07.avif 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e01dca406748ec236bcd3be486d54eda/a0b58/Bitkinex-SD_07.webp 230w", "/en/static/e01dca406748ec236bcd3be486d54eda/bc10c/Bitkinex-SD_07.webp 460w", "/en/static/e01dca406748ec236bcd3be486d54eda/a33a1/Bitkinex-SD_07.webp 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e01dca406748ec236bcd3be486d54eda/81c8e/Bitkinex-SD_07.png 230w", "/en/static/e01dca406748ec236bcd3be486d54eda/08a84/Bitkinex-SD_07.png 460w", "/en/static/e01dca406748ec236bcd3be486d54eda/d4713/Bitkinex-SD_07.png 531w"],
              "sizes": "(max-width: 531px) 100vw, 531px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e01dca406748ec236bcd3be486d54eda/d4713/Bitkinex-SD_07.png",
              "alt": "Bitkinex - FTP Access to the internal SD Card",
              "title": "Bitkinex - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-8",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-8",
        "aria-label": "step 8 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 8`}</h3>
    <p>{`You are connected to your camera and able to browse through your recordings.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      